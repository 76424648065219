<template>
  <b-modal
    ref="modal-edit-work-area"
    size="lg"
    title="Editar Área de Trabajo"
    :no-close-on-backdrop="true"
    @hide="closeModalEditWorkArea"
  >
    <template #modal-title>
      <div class="d-flex justify-content-start align-items-center text-white">
        <tabler-icon
          icon="SitemapIcon"
          size="20"
          class="mr-1"
        />
        <h4 class="m-0 p-0 text-white">
          Edit {{ workAreaName }}
        </h4>
      </div>
    </template>
    <b-container class="p-1">
      <validation-observer ref="formEditWorkArea">
        <b-row class="my-1">
          <b-col cols="12">
            <b-form-group
              id="input-name-work-area"
              label="Name Work Area:"
              label-for="name-work-area"
            >
              <validation-provider
                v-slot="{ errors }"
                name="validation-name"
                rules="required"
              >
                <b-form-input
                  id="input-name"
                  v-model="name"
                  :placeholder="'Enter the name'"
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'border-danger' : ''"
                />
                <small class="text-danger">
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>

          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="12">
            <b-form-group
              :label="'Belongs to: '"
              :label-for="'belong'"
            >
              <v-select
                :id="'select-belong'"
                :v-model="parent.id"
                label="name"
                :options="options"
                :reduce="(option) => option.id"
                width="100%"
                @input="onBelongSelect"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-container>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-between align-items-center">
        <!-- Button: Delete (izquierda) -->
        <b-button
          variant="danger"
          @click="deleteWorkArea"
        >
          Delete
        </b-button>
        <!-- Button: Save (derecha) -->
        <b-button
          variant="primary"
          @click="editWorkArea"
        >
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import WorkAreasService from '@/views/commons/organization-chart/services/work_areas.service';

export default {
  name: 'ModalEditWorkArea',
  props: {
    parent: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    workAreaId: {
      type: Number,
      required: true,
      default: 0,
    },
    workAreaName: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      name: '',
      belong: null,
      options: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  mounted() {
    this.name = this.workAreaName;
    this.toggleModal('modal-edit-work-area');
    this.getWorkAreas().then(data => {
      this.options = data;
    });
    this.belong = this.parent.id;
  },
  methods: {
    async getWorkAreas() {
      try {
        const params = {
          parent_id: null,
        };
        const data = await WorkAreasService.getWorkAreas(
          params,
        );
        if (data.status === 200) {
          return data.data;
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async editWorkArea() {
      try {
        const result = await this.$refs.formEditWorkArea.validate();
        if (!result) {
          return;
        }
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        this.addPreloader();
        const params = {
          work_area_id: this.workAreaId,
          parent_id: this.belong,
          name: this.name,
          user_id: this.currentUser.user_id,
        };
        const response = await WorkAreasService.editWorkArea(params);
        if (response.status === 200) {
          this.showSuccessSwal('Success', 'Edit Work Area Success');
          this.closeModalEditWorkArea();
          this.$emit('refreshSelects', (this.parent.level));
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
      }
    },
    async deleteWorkArea() {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        this.addPreloader();
        const params = {
          work_area_id: this.workAreaId,
          user_id: this.currentUser.user_id,
        };
        const data = await WorkAreasService.deleteWorkArea(params);
        this.removePreloader();
        if (data.data[0].result === 0) {
          // Mostrar un mensaje de advertencia si result es 0 (tiene hijos)
          this.showWarningSwal('Warning', 'Cannot delete because it has children.');
        } else if (data.data[0].result === 1) {
          // Mostrar un mensaje satisfactorio si result es 1 (eliminación exitosa)
          this.showSuccessSwal('Success', 'Delete Work Area Success');
          this.closeModalEditWorkArea();
          this.$emit('refreshSelects', (this.parent.level));
        }
      } catch (error) {
        this.showErrorSwal();
        this.removePreloader();
      }
    },
    closeModalEditWorkArea() {
      this.$emit('closeModalEditWorkArea', this.parent.level);
    },
    onBelongSelect(selected) {
      this.belong = selected;
    },
  },
};
</script>
