<template>
  <b-modal
    ref="modal-assign"
    size="xmd"
    title="Assign to the organization chart"
    :no-close-on-backdrop="true"
    @hide="closeModalAssign"
  >
    <template #modal-title>
      <div class="d-flex justify-content-start align-items-center text-white">
        <tabler-icon
          icon="SitemapIcon"
          size="20"
          class="mr-1"
        />
        <h4 class="m-0 p-0 text-white">
          Assign to the organization chart
        </h4>
      </div>
    </template>
    <b-container class="p-1">
      <validation-observer ref="formAssignWorkArea">
        <b-row>
          <b-col
            cols="5"
          >
            <b-card>
              <h3 class="text-center">
                Select Work Area
              </h3>
              <b-row
                v-for="(level, index) in levels"
                :key="index"
              >
                <b-col :cols="!level.visibleRemoveLevel || index === 0 ? (level.selectedOption?'11':'12') :(level.selectedOption?'10':'11')">
                  <b-form-group
                    :label="'Level ' + (index + 1)"
                    :label-for="'level' + (index + 1)"
                  >
                    <v-select
                      :id="'select-level'+(index+1)"
                      :v-model="level.selectedOption"
                      placeholder="Select a Work Area"
                      label="name"
                      :options="level.options"
                      :reduce="(option) => option.id"
                      width="100%"
                      :clearable="false"
                      :style="!level.selectedOption && validationLevel?'border: 1px solid red;':''"
                      @input="(selectedValue) => handleOptionSelected(selectedValue, index)"
                    />
                    <small
                      v-if="!level.selectedOption && validationLevel"
                      class="text-danger"
                    >
                      Select an option
                    </small>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="level.selectedOption"
                  cols="1"
                  class="d-flex align-items-center justify-content-center"
                >
                  <div :style="(!level.selectedOption && validationLevel) ? 'margin-top:-11px;margin-right:15px' : 'margin-top:10px;margin-right:15px'">
                    <tabler-icon
                      icon="AdjustmentsHorizontalIcon"
                      class="cursor-pointer text-success"
                      size="25"
                      @click="editWorkArea(index)"
                    />
                  </div>
                </b-col>
                <b-col
                  v-if="level.visibleRemoveLevel && index != 0"
                  cols="1"
                  class="d-flex align-items-center justify-content-center"
                >
                  <div :style="(!level.selectedOption && validationLevel) ? 'margin-top:-11px;margin-right:15px' : 'margin-top:10px;margin-right:15px'">
                    <tabler-icon
                      icon="CircleXIcon"
                      class="cursor-pointer text-danger"
                      size="25"
                      @click="removeLevel(index)"
                    />
                  </div>
                </b-col>
                <b-col
                  v-if="level.visibleAddLevel"
                  cols="12"
                  class="d-flex align-items-center justify-content-center"
                >
                  <div>
                    <b-button-group
                      v-if="level.selectedOption"
                      size="sm"
                      class="mx-1"
                    >
                      <b-button
                        variant="primary"
                        @click="addLevel(index)"
                      >Add Level</b-button>
                    </b-button-group>
                    <b-button-group
                      size="sm"
                      class="mx-1"
                    >
                      <b-button
                        variant="primary"
                        @click="addWorkArea(index)"
                      >Add Work Area</b-button>
                    </b-button-group>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="7">
            <b-card>
              <h3 class="text-center">
                Select Employees
              </h3>
              <filter-slot
                :filter="filter"
                :filter-principal="filterPrincipal"
                :total-rows="totalRows"
                :paginate="paginate"
                :start-page="startPage"
                :to-page="toPage"
                @reload="$refs.refTableEmployees.refresh()"
              >
                <template #table>
                  <b-table
                    ref="refTableEmployees"
                    no-border-collapse
                    class="position-relative"
                    :fields="fields"
                    show-empty
                    no-provider-filtering
                    sticky-header="30vh"
                    primary-key="id"
                    responsive="sm"
                    :items="myProvider"
                    :current-page="paginate.currentPage"
                    :per-page="paginate.perPage"
                    :filter="searchInput"
                    :busy.sync="isBusy"
                  >
                    <template #table-busy>
                      <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle mr-1" />
                        <strong>Loading ...</strong>
                      </div>
                    </template>
                    <template #head(selected)>
                      <b-form-checkbox
                        v-model="allEmployeesAreSelected"
                        @input="selectAllEmployees"
                      />
                    </template>
                    <template #cell(selected)="data">
                      <div class="d-flex justify-content-center">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="data.item.selected"
                            :value="1"
                            :unchecked-value="0"
                            name="selected"
                            @change="toggleSelectedEmployee(data.item)"
                          />
                        </b-form-group>
                      </div>
                    </template>
                    <template #cell(work_areas)="data">
                      <div>
                        <ul>
                          <li
                            v-for="item in JSON.parse(data.item.work_areas)"
                            :key="item.work_area"
                          >
                            <div class="d-flex align-items-left">
                              <span>{{ item.work_area }}</span>
                              <tabler-icon
                                v-if="item.responsible === 1"
                                icon="MedalIcon"
                                class="text-success"
                                size="20"
                              />
                              <tabler-icon
                                icon="CircleXIcon"
                                class="text-danger cursor-pointer"
                                size="20"
                                style=" margin-left:3px"
                                @click="removeWorkAreaEmployee(item, data.item)"
                              />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </template>
                    <template #cell(responsible)="data">
                      <div class="d-flex justify-content-center">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="data.item.responsible"
                            :value="1"
                            :unchecked-value="0"
                            name="responsible"
                            :disabled="data.item.selected===0"
                          />
                        </b-form-group>
                      </div>
                    </template>
                  </b-table>
                </template>
              </filter-slot>
            </b-card>

          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-card v-if="employeesSelectedForSave.length >0">
              <h3 class="text-center">
                Selected Employees for ("{{ workAreaName }}")
              </h3>
              <b-row>
                <b-col cols="12">
                  <div
                    v-for="employee in employeesSelectedForSave"
                    :key="employee.id"
                    class="d-flex align-items-center mt-1"
                  >
                    <tabler-icon
                      icon="UserIcon"
                      class="text-secondary"
                      size="20"
                    />
                    <span>{{ employee.name }}</span>
                    <tabler-icon
                      v-if="employee.responsible === 1"
                      icon="MedalIcon"
                      class="text-success"
                      size="20"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </validation-observer>
    </b-container>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end align-items-center">
        <b-button
          variant="primary"
          @click="assignEmployees"
        >
          Save
        </b-button>
      </div>
    </template>
    <modal-add-work-area
      v-if="showModalAddWorkArea"
      :parent="parent"
      @closeModalAddWorkArea="closeModalAddWorkArea"
      @refreshSelects="refreshSelects"
    />
    <modal-edit-work-area
      v-if="showModalEditWorkArea"
      :parent="parent"
      :work-area-id="workAreaId"
      :work-area-name="workAreaName"
      @closeModalEditWorkArea="closeModalEditWorkArea"
      @refreshSelects="refreshSelects"
    />
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import WorkAreasService from '@/views/commons/organization-chart/services/work_areas.service';
import ModalAddWorkArea from '@/views/commons/organization-chart/components/modals/ModalAddWorkArea.vue';
import ModalEditWorkArea from '@/views/commons/organization-chart/components/modals/ModalEditWorkArea.vue';
import helpdeskService from '@/views/commons/components/helpdesk/services/helpdesk.service';

export default {
  name: 'ModalAssign',
  components: {
    ModalAddWorkArea,
    ModalEditWorkArea,
  },
  data() {
    return {
      levels: [
        {
          selectedOption: null,
          options: [],
          visibleAddLevel: false,
          visibleRemoveLevel: false,
        },
      ],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
      searchInput: '',
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Employee...',
        model: '',
      },
      fields: [
        {
          key: 'selected',
          label: '',
          visible: true,
        },
        { key: 'name', label: 'Employee' },
        { key: 'work_areas', label: 'Assigned', thClass: 'text-center' },
        {
          key: 'responsible', label: 'Responsible', thClass: 'text-center', tdClass: 'text-center',
        },
      ],
      parent: {
        id: 1,
        name: 'CEO',
        level: null,
      },
      filter: [
        {
          type: 'select',
          margin: true,
          showLabel: true,
          label: 'Module',
          model: null,
          options: [],
          reduce: 'id',
          selectText: 'name',
          cols: 12,
          visible: true,
        },
      ],
      employees: [],
      employees2: [],
      levelAddWorkArea: '',
      showModalAddWorkArea: false,
      showModalEditWorkArea: false,
      employeesSelectedForSave: [],
      allEmployeesAreSelected: false,
      supervised: null,
      tempOptions: [],
      validationLevel: false,
      validationSupervised: false,
      validationEmployees: false,
      workAreaId: null,
      workAreaName: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  mounted() {
    this.toggleModal('modal-assign');
    this.getEmployees();
    this.getWorkAreas(1).then(data => {
      this.levels[0].options = data;
      this.levels[0].visibleAddLevel = true;
    });
    this.getModules();
  },
  methods: {
    async removeWorkAreaEmployee(workArea, employee) {
      console.log(workArea, employee);
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;

        this.addPreloader();
        const params = {
          work_area_id: workArea.id,
          employee_id: employee.id,
          user_id: this.currentUser.user_id,
        };
        const data = await WorkAreasService.removeWorkAreaEmployee(params);
        if (data.status === 200) {
          this.showSuccessSwal('Success', 'Employee satisfactorily removed from work area');
          this.$refs.refTableEmployees.refresh();
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
      }
    },
    toggleSelectedEmployee(employee) {
      if (employee.selected) {
      // Agregar al arreglo employeesSelectedForSave si está marcado
        this.employeesSelectedForSave.push(employee);
      } else {
      // Eliminar del arreglo employeesSelectedForSave si está desmarcado
        const index = this.employeesSelectedForSave.findIndex(
          e => e.id === employee.id,
        );
        if (index !== -1) {
          this.employeesSelectedForSave.splice(index, 1);
          // poner 0 a responsible
          this.employees.map(e => {
            if (e.id === employee.id) {
              e.responsible = 0;
            }
            return e;
          });
        }
      }
    },
    async assignEmployees() {
      try {
        // recorrer levels y verificar si hay un selectedOption null
        this.validationLevel = false;
        this.validationSupervised = false;
        this.validationEmployees = false;
        this.levels.forEach(element => {
          if (element.selectedOption === null) {
            this.validationLevel = true;
          }
        });
        if (this.employeesSelectedForSave.length === 0) {
          this.validationEmployees = true;
        }
        if (this.validationLevel || this.validationSupervised) {
          return;
        }
        if (this.validationEmployees) {
          this.showWarningSwal('Warning', 'Select at least one employee');
          return;
        }
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;

        this.addPreloader();
        const params = {
          work_area_id: this.parent.id,
          employees: this.employeesSelectedForSave,
          user_id: this.currentUser.user_id,
        };
        const data = await WorkAreasService.assignEmployees(params);
        if (data.status === 200) {
          this.showSuccessSwal('Success', 'Employee successfully assigned');
          this.$emit('closeModalAssign');
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
      }
    },
    async myProvider(ctx) {
      try {
        const params = {
          text: this.filterPrincipal.model,
          module_id: this.filter[0].model,
          perpage: ctx.perPage,
          page: ctx.currentPage,
        };
        const { data } = await WorkAreasService.searchEmployeesByModule(
          params,
        );
        // Comparar y ajustar los valores seleccionados
        data.data.forEach(employee => {
          const selectedEmployee = this.employeesSelectedForSave.find(
            selected => selected.id === employee.id,
          );

          if (selectedEmployee) {
            // Validar la propiedad 'selected'
            employee.selected = selectedEmployee.selected === 1 ? 1 : 0;

            // Validar la propiedad 'responsible'
            employee.responsible = selectedEmployee.responsible === 1 ? 1 : 0;
          } else {
            // Si el empleado no está en employeesSelectedForSave, establecer a 0
            employee.selected = 0;
            employee.responsible = 0;
          }
        });
        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.totalRows = data.total;
        this.toPage = data.to;
        this.employees = data.data;
        return this.employees || [];
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getEmployees() {
      try {
        const params = {
          text: null,
          module_id: null,
          perpage: 1000,
          page: 0,
        };
        const { data } = await WorkAreasService.searchEmployeesByModule(
          params,
        );
        this.employees2 = data.data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getWorkAreas(number_parent_id) {
      try {
        const params = {
          parent_id: number_parent_id,
        };
        const data = await WorkAreasService.getWorkAreas(
          params,
        );
        if (data.status === 200) {
          return data.data;
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    handleOptionSelected(selectedOption, index) {
      this.levels.splice(index + 1);
      this.levels[index].visibleAddLevel = true;
      if (selectedOption === null) {
        this.levels[index].selectedOption = null;
        if (index === 0) {
          this.parent.id = null;
          this.parent.level = null;
        } else {
          this.parent.id = this.levels[index - 1].selectedOption;
          this.parent.level = index - 1;
        }
        this.$refs.refTableEmployees.refresh();
        return;
      }
      this.levels[index].selectedOption = selectedOption;
      this.parent.id = selectedOption;
      // recorrer levels[index].options para poner el name a parent.name
      this.levels[index].options.forEach(element => {
        if (element.id === selectedOption) {
          this.parent.name = element.name;
          this.workAreaName = element.name;
        }
      });
      this.parent.level = index;
      if (index === 0) {
        this.$refs.refTableEmployees.refresh();
      }
      this.getWorkAreas(selectedOption).then(data => {
        this.tempOptions = data;
      });
      this.validationLevel = false;
    },
    closeModalAssign() {
      this.$emit('closeModalAssign');
    },
    closeModalAddWorkArea(index) {
      if (index === null) {
        this.parent.id = 1;
        this.parent.level = null;
        this.parent.name = 'CEO';
      } else {
        this.parent.id = this.levels[index].selectedOption;
        this.parent.level = (index);
        this.levels[index].options.forEach(element => {
          if (element.id === this.parent.id) {
            this.parent.name = element.name;
          }
        });
      }

      this.showModalAddWorkArea = false;
    },
    selectAllEmployees() {
      if (this.allEmployeesAreSelected) {
        this.employeesSelectedForSave = this.employees.slice();
        this.employeesSelectedForSave.forEach(employee => {
          employee.selected = 1;
        });
      } else {
        this.employeesSelectedForSave = [];
        // ponerle valor 0
        this.employees.forEach(employee => {
          employee.selected = 0;
        });
      }
    },
    refreshSelects(level) {
      if (level === null) {
        this.getWorkAreas(1).then(
          data => {
            this.levels[0].options = data;
          },
        );
      } else if (level === 0) {
        this.getWorkAreas(this.levels[0].selectedOption).then(
          data => {
            this.levels[0 + 1].options = data;
          },
        );
        this.removeLevel(0 + 1);
        this.levels.splice((0 + 1) + 1);
      } else {
        this.getWorkAreas(this.levels[level].selectedOption).then(
          data => {
            this.levels[level + 1].options = data;
          },
        );
        this.removeLevel(level + 1);
        // borrar los selects que estan despues de level
        this.levels.splice((level + 1) + 1);
      }
    },
    removeLevel(levelIndex) {
      console.log(levelIndex);
      this.levels.splice(levelIndex, 1);
      if (levelIndex === 1) {
        this.parent.id = 1;
        this.parent.level = null;
        this.parent.name = 'CEO';
      } else {
        this.parent.id = this.levels[levelIndex - 1].selectedOption;
        this.parent.level = levelIndex - 1;
        this.levels[levelIndex - 1].options.forEach(element => {
          if (element.id === this.parent.id) {
            this.parent.name = element.name;
          }
        });
      }

      this.levels[levelIndex - 1].visibleAddLevel = true;
      this.levels[levelIndex - 1].visibleRemoveLevel = true;
      this.getWorkAreas(this.levels[levelIndex - 1].selectedOption).then(
        data => {
          this.tempOptions = data;
        },
      );
      this.workAreaName = this.levels[levelIndex - 1].options.find(
        element => element.id === this.levels[levelIndex - 1].selectedOption,
      ).name;
      this.workAreaId = this.levels[levelIndex - 1].selectedOption;
    },
    addLevel(index) {
      if (this.levels.length <= index + 1) {
        this.levels.push({
          selectedOption: null,
          options: [],
          visibleAddLevel: true,
          visibleRemoveLevel: true,
        });
        this.levels[index].visibleAddLevel = false;
        this.levels[index].visibleRemoveLevel = false;
        if (this.tempOptions.length > 0) {
          this.levels[index + 1].options = this.tempOptions;
        }
      }
    },
    addWorkArea(index) {
      this.showModalAddWorkArea = true;
      if (index === 0) {
        this.parent.id = 1;
        this.parent.level = null;
        this.parent.name = 'CEO';
      } else {
        this.parent.id = this.levels[index - 1].selectedOption;
        this.parent.level = (index - 1);
        this.levels[index - 1].options.forEach(element => {
          if (element.id === this.parent.id) {
            this.parent.name = element.name;
          }
        });
      }
    },
    selectSupervised(selectedSupervised) {
      this.validateSupervised = false;
      this.supervised = selectedSupervised;
    },
    editWorkArea(index) {
      console.log('index', index);
      this.showModalEditWorkArea = true;
      if (index === 0) {
        this.parent.id = 1;
        this.parent.level = null;
        this.parent.name = 'CEO';
      } else {
        this.parent.id = this.levels[index - 1].selectedOption;
        this.parent.level = (index - 1);
        this.levels[index - 1].options.forEach(element => {
          if (element.id === this.parent.id) {
            this.parent.name = element.name;
          }
        });
      }
      this.workAreaName = this.levels[index].options.find(
        element => element.id === this.levels[index].selectedOption,
      ).name;
      this.workAreaId = this.levels[index].selectedOption;
    },
    closeModalEditWorkArea() {
      this.showModalEditWorkArea = false;
    },
    async getModules() {
      const response = await helpdeskService.getModules();
      if (response.status === 200) {
        this.filter[0].options = [{ name: 'Select a module', id: null }];
        this.filter[0].options = [
          ...this.filter[0].options,
          ...response.data,
        ];
      }
    },
  },
};
</script>
