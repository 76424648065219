<template>
  <b-modal
    ref="modal-add-work-area"
    size="lg"
    title="Agregar Área de Trabajo"
    :no-close-on-backdrop="true"
    @hide="closeModalAddWorkArea"
  >
    <template #modal-title>
      <div class="d-flex justify-content-start align-items-center text-white">
        <tabler-icon
          icon="SitemapIcon"
          size="20"
          class="mr-1"
        />
        <h4 class="m-0 p-0 text-white">
          Add work area
        </h4>
      </div>
    </template>
    <b-container class="p-1">
      <validation-observer ref="formAddWorkArea">
        <b-row>
          <b-col>
            Parent module:
            <p
              class="rounded text-primary border-primary font-medium-1 text-center py-1s"
            >
              {{ parent.name }}
            </p>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="12">
            <b-form-group
              id="input-new-work-area"
              label="New work area (Sub-Module, Area, Sub-Area, Group, etc...):"
              label-for="new-work-area"
            >
              <validation-provider
                v-slot="{ errors }"
                name="validation-name"
                rules="required"
              >
                <b-form-input
                  id="input-name"
                  v-model="name"
                  :placeholder="'Enter the name'"
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'border-danger' : ''"
                />
                <small class="text-danger">
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
      </validation-observer>
    </b-container>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end align-items-center">
        <b-button
          variant="primary"
          @click="insertWorkArea"
        >
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import WorkAreasService from '@/views/commons/organization-chart/services/work_areas.service';
import { mapGetters } from 'vuex';

export default {
  name: 'ModalAddWorkArea',
  props: {
    parent: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      name: '',
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  mounted() {
    this.toggleModal('modal-add-work-area');
  },
  methods: {
    async insertWorkArea() {
      try {
        const result = await this.$refs.formAddWorkArea.validate();
        if (!result) {
          return;
        }
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        this.addPreloader();
        const workArea = {
          name: this.name,
          parent_id: this.parent.id,
          user_id: this.currentUser.user_id,
        };
        const response = await WorkAreasService.insertWorkArea(workArea);
        if (response.status === 200) {
          this.showSuccessSwal('Success', 'Insert Work Area Success');
          this.closeModalAddWorkArea();
          this.$emit('refreshSelects', this.parent.level);
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
      }
    },

    closeModalAddWorkArea() {
      this.$emit('closeModalAddWorkArea', this.parent.level);
    },
  },
};
</script>
